<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          height="40px"
          label="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="rounded-lg"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="state"
          :items="state_list"
          :search-input.sync="state_search"
          :loading="state_list_loading"
          item-text="name"
          item-value="id"
          outlined
          dense
          no-filter
          height="40px"
          label="Filter by State"
          class="rounded-lg"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          auto-select-first
          clearable
          :menu-props="{
            bottom: true,
            offsetY: true,
          }"
          append-icon="mdi-chevron-down"
          v-model="city"
          :items="city_list"
          :search-input.sync="city_search"
          :loading="city_list_loading"
          item-text="name"
          item-value="id"
          outlined
          dense
          no-filter
          height="40px"
          label="Filter by City"
          class="rounded-lg"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="filter_by_role"
          clearable
          :items="role_list"
          item-text="label"
          item-value="value"
          label="Filter by Role"
          outlined
          dense
          height="40px"
          class="rounded-lg"
        ></v-select>
      </v-col>
      <v-col col="4" class="text-right">
        <v-btn
          class="text-capitalize px-7 rounded-lg"
          color="primary"
          outlined
          height="40px"
          @click="addTeam({ show: true, id: '', type: 'add' })"
        >
          <v-icon size="16" left>mdi-plus</v-icon>Add New Teammate
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      hide-default-footer
      mobile-breakpoint
      class="elevation-3"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      :headers="headers"
      :items="items"
      loading-text="Please wait.... Loading"
      color="primary"
    >
      <template v-slot:[`item.created`]="{ item }">
        <div class="py-4">
          {{ $utils.formatDate(item.created) }}
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="py-4">
          <div v-if="item.name">{{ item.name }}</div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <div class="py-4">
          <div v-if="item.user.mobile || item.user.email || item.full_address">
            <div v-if="item.user.mobile">{{ item.user.mobile_display }}</div>
            <div v-if="item.user.email">{{ item.user.email }}</div>
            <div v-if="item.full_address">
              {{ item.full_address }}
            </div>
          </div>
          <v-icon v-else>mdi-minus</v-icon>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex flex-column justify-center">
          <v-switch
            hide-details
            color="success"
            v-model="item.is_active"
            @change="changeStatus(item.is_active, item.id)"
            :label="item.is_active ? 'Active' : 'In-active'"
          ></v-switch>
          <v-switch
            v-if="showAvailabilityToggle(item)"
            hide-details
            color="success"
            v-model="item.is_available"
            @change="changeAvailability(item.is_available, item.id)"
            :label="item.is_available ? 'Available' : 'Unavailable'"
          ></v-switch>
        </div>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <v-chip small>{{ item.role_display }}</v-chip>
        <div v-if="item.role === $keys.ACCOUNTS" class="mt-1">
          <div>Payout Balance: {{ item.payout_balance }}</div>
          <div v-if="item.payout_balance_request">
            <div class="font-weight-bold text-caption">Balance Request</div>
            <div>Amount: {{ item.payout_balance_request.amount }}</div>
            <div class="d-flex justify-center align-center" style="gap: 10px">
              <span
                class="text-caption text-decoration-underline pointer"
                @click="
                  approveReject(item.payout_balance_request.id, 'approve')
                "
              >
                Approve
              </span>
              <span
                class="text-caption text-decoration-underline pointer"
                @click="approveReject(item.payout_balance_request.id, 'reject')"
              >
                Reject
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            class="text-capitalize rounded-lg"
            color="primary"
            height="32px"
            @click="
              addTeam({
                show: true,
                type: 'edit',
                id: item.id,
              })
            "
          >
            edit
          </v-btn>
          <v-btn
            outlined
            small
            class="text-capitalize rounded-lg"
            height="32px"
            color="primary"
            @click="
              toggleConfirmationDialog({ show: true, action: 'delete' }),
                (user_id = item.id)
            "
          >
            delete
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>
    <add-team @reload-list="getTeamList" />
    <ConfirmationDialog @confirmed="deleteUser" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
  </div>
</template>
<script>
import list from "@/common/roles/index";
import _ from "lodash";
import addTeam from "./addTeam.vue";
import { mapActions } from "vuex";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { addTeam, ConfirmationDialog },
  data() {
    return {
      page_total: 1,
      page_number: 1,
      page_count: 10,
      total_count: 0,
      search: "",
      loading: false,
      user_id: null,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      role_list: list.roles,
      filter_by_role: "",
      headers: [
        {
          sortable: false,
          text: "Date  Joined",
          value: "created",
          width: 150,
        },
        {
          sortable: false,
          text: "Name",
          value: "name",
          width: 150,
        },
        {
          sortable: false,
          text: "Contact",
          value: "contact",
          width: 250,
        },
        {
          sortable: false,
          text: "Role",
          value: "role",
          width: 180,
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
          width: 150,
        },
        {
          sortable: false,
          text: "Action",
          width: 180,
          value: "action",
        },
      ],
      items: [],

      state: null,
      state_list: [],
      state_search: "",
      state_list_loading: false,

      city: null,
      city_list: [],
      city_search: "",
      city_list_loading: false,
    };
  },
  computed: {
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },
    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    search() {
      this.debouncedReloadData();
    },

    state() {
      this.getTeamList();
      this.updateRoute();
    },

    city() {
      this.getTeamList();
      this.updateRoute();
    },

    page_count() {
      this.getTeamList();
    },

    page_number() {
      this.getTeamList();
      this.updateRoute();
    },

    filter_by_role() {
      this.getTeamList();
      this.updateRoute();
    },

    state_search: _.debounce(function (query) {
      if (this.state_list_loading) return;

      if (query && !this.state) {
        this.getStateList();
      }
    }, 1000),

    city_search: _.debounce(function (query) {
      if (this.city_list_loading) return;

      if (query && !this.city) {
        this.getCityList();
      }
    }, 1000),
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    this.updateRoute();
  },

  mounted() {
    this.getTeamList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      addTeam: "admin/addTeam",
      showSnackbar: "app/showSnackbar",
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
    }),

    navClickHandler(navKey) {
      if (navKey === "team_management") {
        this.getTeamList();
        this.updateRoute();
      }
    },

    debouncedReloadData: _.debounce(function () {
      this.getTeamList();
      this.updateRoute();
    }, 1000),

    updateRoute() {
      const query = {};

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    showAvailabilityToggle(user) {
      return [
        this.$keys.SALES_EXECUTIVE,
        this.$keys.OPERATIONS_EXECUTIVE,
      ].includes(user.role);
    },

    approveReject(id, status) {
      let url;
      switch (status) {
        case "approve":
          url = this.$urls.team.payoutBalance.requestApprove;
          break;
        case "reject":
          url = this.$urls.team.payoutBalance.requestReject;
          break;
        default:
          return;
      }

      return this.$request(this.$keys.POST, url, {
        data: { id },
        onSuccess: (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.getTeamList();
        },
      });
    },

    getStateList() {
      this.state_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.state_search,
      };

      const onSuccess = (res) => {
        this.state_list = res.data.data;
      };

      const onFinally = () => {
        this.state_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getCityList() {
      this.city_list_loading = true;

      const params = {
        apply_pagination: false,
        state_id: this.state,
        search: this.city_search,
      };

      const onSuccess = (res) => {
        this.city_list = res.data.data;
      };

      const onFinally = () => {
        this.city_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getTeamList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      let params = {};
      params["page_number"] = this.page_number;
      params["page_count"] = this.page_count;
      if (this.search) params["search"] = this.search;
      if (this.state) params["state_id"] = this.state;
      if (this.city) params["city_id"] = this.city;
      if (this.filter_by_role) params["role"] = this.filter_by_role;

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    changeStatus(value, id) {
      const data = {
        is_active: value,
      };
      const params = {
        user_id: id,
      };

      return this.$request(this.$keys.PUT, this.$urls.team.update, {
        params,
        data,
      });
    },

    changeAvailability(value, id) {
      const data = {
        availability: value ? "available" : "unavailable",
      };
      const params = {
        user_id: id,
      };

      console.log(data, params);

      return this.$request(this.$keys.PUT, this.$urls.team.update, {
        params,
        data,
      });
    },

    deleteUser() {
      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getTeamList();
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      let params = {};
      params["user_id"] = this.user_id;

      return this.$request(this.$keys.DELETE, this.$urls.team.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>