export default {
    roles: [
        {
            value: "admin",
            label: "Admin",
        },
        {
            value: "super_admin",
            label: "Super Admin",
        },
        {
            value: "accounts",
            label: "Accounts",
        },
        {
            value: "sales_head",
            label: "Sales Head",
        },
        {
            value: "sales_executive",
            label: "Sales Executive",
        },
        {
            value: "operations_head",
            label: "Operations Head",
        },
        {
            value: "operations_executive",
            label: "Operations Executive",
        },
    ],
};
