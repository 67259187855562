var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","height":"40px","label":"Search name, email, or etc.","prepend-inner-icon":"mdi-magnify","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"auto-select-first":"","clearable":"","menu-props":{
          bottom: true,
          offsetY: true,
        },"append-icon":"mdi-chevron-down","items":_vm.state_list,"search-input":_vm.state_search,"loading":_vm.state_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","height":"40px","label":"Filter by State"},on:{"update:searchInput":function($event){_vm.state_search=$event},"update:search-input":function($event){_vm.state_search=$event}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"auto-select-first":"","clearable":"","menu-props":{
          bottom: true,
          offsetY: true,
        },"append-icon":"mdi-chevron-down","items":_vm.city_list,"search-input":_vm.city_search,"loading":_vm.city_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","height":"40px","label":"Filter by City"},on:{"update:searchInput":function($event){_vm.city_search=$event},"update:search-input":function($event){_vm.city_search=$event}},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","clearable":"","items":_vm.role_list,"item-text":"label","item-value":"value","label":"Filter by Role","outlined":"","dense":"","height":"40px"},model:{value:(_vm.filter_by_role),callback:function ($$v) {_vm.filter_by_role=$$v},expression:"filter_by_role"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"col":"4"}},[_c('v-btn',{staticClass:"text-capitalize px-7 rounded-lg",attrs:{"color":"primary","outlined":"","height":"40px"},on:{"click":function($event){return _vm.addTeam({ show: true, id: '', type: 'add' })}}},[_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v("mdi-plus")]),_vm._v("Add New Teammate ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"hide-default-footer":"","mobile-breakpoint":"","items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"loading-text":"Please wait.... Loading","color":"primary"},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-4"},[_vm._v(" "+_vm._s(_vm.$utils.formatDate(item.created))+" ")])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-4"},[(item.name)?_c('div',[_vm._v(_vm._s(item.name))]):_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.contact",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-4"},[(item.user.mobile || item.user.email || item.full_address)?_c('div',[(item.user.mobile)?_c('div',[_vm._v(_vm._s(item.user.mobile_display))]):_vm._e(),(item.user.email)?_c('div',[_vm._v(_vm._s(item.user.email))]):_vm._e(),(item.full_address)?_c('div',[_vm._v(" "+_vm._s(item.full_address)+" ")]):_vm._e()]):_c('v-icon',[_vm._v("mdi-minus")])],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-switch',{attrs:{"hide-details":"","color":"success","label":item.is_active ? 'Active' : 'In-active'},on:{"change":function($event){return _vm.changeStatus(item.is_active, item.id)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}}),(_vm.showAvailabilityToggle(item))?_c('v-switch',{attrs:{"hide-details":"","color":"success","label":item.is_available ? 'Available' : 'Unavailable'},on:{"change":function($event){return _vm.changeAvailability(item.is_available, item.id)}},model:{value:(item.is_available),callback:function ($$v) {_vm.$set(item, "is_available", $$v)},expression:"item.is_available"}}):_vm._e()],1)]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.role_display))]),(item.role === _vm.$keys.ACCOUNTS)?_c('div',{staticClass:"mt-1"},[_c('div',[_vm._v("Payout Balance: "+_vm._s(item.payout_balance))]),(item.payout_balance_request)?_c('div',[_c('div',{staticClass:"font-weight-bold text-caption"},[_vm._v("Balance Request")]),_c('div',[_vm._v("Amount: "+_vm._s(item.payout_balance_request.amount))]),_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"10px"}},[_c('span',{staticClass:"text-caption text-decoration-underline pointer",on:{"click":function($event){return _vm.approveReject(item.payout_balance_request.id, 'approve')}}},[_vm._v(" Approve ")]),_c('span',{staticClass:"text-caption text-decoration-underline pointer",on:{"click":function($event){return _vm.approveReject(item.payout_balance_request.id, 'reject')}}},[_vm._v(" Reject ")])])]):_vm._e()]):_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.addTeam({
              show: true,
              type: 'edit',
              id: item.id,
            })}}},[_vm._v(" edit ")]),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"outlined":"","small":"","height":"32px","color":"primary"},on:{"click":function($event){_vm.toggleConfirmationDialog({ show: true, action: 'delete' }),
              (_vm.user_id = item.id)}}},[_vm._v(" delete ")])],1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('add-team',{on:{"reload-list":_vm.getTeamList}}),_c('ConfirmationDialog',{attrs:{"height":"130px","width":"130px"},on:{"confirmed":_vm.deleteUser},scopedSlots:_vm._u([{key:"confirmBtnText",fn:function(){return [_vm._v(" Yes Delete ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }